import React, { useState, useEffect } from 'react'

import { graphql, useStaticQuery, navigate, Link } from 'gatsby'
import Img from 'gatsby-image'
// import BackgroundImage from 'gatsby-background-image'

const SolarSystem = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory:  { eq: "celestial"} }) {
                nodes {
                    name
                    childImageSharp {
                        fluid(maxWidth: 300){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }

            file(relativePath: { eq: "universe.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const getImage = (imageName) => {
        return data.allFile.nodes.filter(({name}) => name === imageName)[0].childImageSharp.fluid
    }

    const sun = getImage("Sun")
    const portal = getImage("planet-portal")
    const decolonizing = getImage("planet-decolonizing")
    const theLab = getImage("planet-the-lab")
    const imaginarium = getImage("planet-imaginarium")

    let dragData;
    let dropAt;

    const planetsCount = 4;
    const [ planetsAligned, setPlanetsAligned ] = useState(0);

    function handleDrag(event){
        // event.dataTransfer.setData('text', event.target.id) // ideal but setData & getData on dataTransfer not working
        dragData = event.target.id
        dropAt = event.target.dataset.drop
        document.getElementById(dropAt).style.border = '2px dashed #ffd32a'
    }

    function handleDragEnd(){
        if(dropAt){
            document.getElementById(dropAt).style.border = 'none'
        }
    }

    function handleDragOver(event){
        event.preventDefault();
    }

    function handleDrop(event){
        event.preventDefault()
        // let data = event.dataTransfer.getData("text/uri-list"); this is not working so using dragData variable as a replacement
        event.target.appendChild(document.getElementById(dragData));
        setPlanetsAligned(planetsAligned + 1)
        event.target.style.border = 'none'
    }

    const animateDropZone = (dropZoneName) => {
        document.getElementById(dropZoneName).className += ' animated-zone'
    }

    const removeDropZoneAnimation = (dropZoneName) => {
        document.getElementById(dropZoneName).classList.remove('animated-zone')
    }

    const keyPressNavigation = (event, link) => {
        if(event.keyCode === 13){
            navigate(link)
        }
    }

    const planets = [
        {name: "portal", image: portal.src, link: '/portal'},
        {name: "decolonizing", image: decolonizing.src, link: '/decolonize-the-art-world'},
        {name: "imaginarium", image: imaginarium.src, link: '/imaginarium'},
        {name: "the-lab", image: theLab.src, link: '/the-lab'}
    ]

    useEffect(() => {
        if(planetsAligned === planetsCount){
            setTimeout(function(){
                document.getElementById('celestial-bodies').style.display = 'none'
            }, 1500)
            //corresponds to animation delay in solar-system.css
        }
    })

    return (
            
        <div className="galaxy">

            <div className="galaxy-instructions">
                <p className="is-size-5 left click-drag-mobile">
                    Click and drag the planets to enter universe
                </p>

                <p id="auto-align" className="left">
                    <button className="button is-info" onClick={() => { setPlanetsAligned(planetsCount)}}>Auto Align Planets</button>
                </p>
                
                <p className="is-size-5 is-size-7-mobile right">
                    Click on a planet to visit it
                </p>
            </div>

            {/* Background image */}
            <Img fluid={data.file.childImageSharp.fluid} 
                style={{
                    position: 'absolute', height: '100vh', width: '100vw'
                }}
            />
            
            <div className="celestial-bodies" id="celestial-bodies">
                <Img fluid={sun} className="celestial-body sun" id="sun" imgStyle={
                    {
                        width: '15em',
                        height: '15em'
                    }
                }/>

                {
                    // planets 🔭

                    planets.map(( { name, image, link }, index ) => {
                        const dropZoneName = `${name}-drop`
                        let planetPosClass = "planet-pos"
                        let planetClass = "planet"

                        if(name.includes('the-lab') || name.includes('imaginarium')){
                            planetPosClass += " large-planet-pos"
                            planetClass += " large-planet"
                        }

                        return (
                            <>
                            <div key={index} id={name} className="orbit">
                                <div className={planetPosClass}>
                                    <div className={planetClass} style={{backgroundImage: `url(${image})`}}
                                        draggable="true" grab="true" onDragStart={(event) => handleDrag(event)} 
                                        onDragEnd={handleDragEnd}
                                        onMouseOver={ () => animateDropZone(dropZoneName) }
                                        onFocus={ () => animateDropZone(dropZoneName) }
                                        onMouseLeave={ () => removeDropZoneAnimation(dropZoneName)}
                                        id={`${name}-planet`}
                                        data-drop={dropZoneName}
                                        onClick={() => { link && navigate(link) }}
                                        onKeyDown={(event) => keyPressNavigation(event, link)}
                                        role="button"
                                        tabIndex={index}
                                    >
                                    </div>
                                </div>
                            </div>

                            <div id={dropZoneName} dropeffect="move" className="droparea"
                                onDragOver={(event) => handleDragOver(event)}
                                onDrop={(event) => handleDrop(event)}
                            >
                            </div>
                            </>
                        )
                    })
                }
            </div>

            {
                planetsAligned === planetsCount &&

                    <div className="a-kra section">

                        <div className="content has-text-justified has-text-left-mobile column is-size-4">
                            <h2 style={{fontFamily: 'Montserrat Subrayada, Roboto Mono, sans-serif'}} className="is-size-1">
                                Welcome to Our Universe
                            </h2>

                            <p className="is-size-3">
                                nea ɛbɛ ba biara aba dada
                            </p>

                            <p className="is-size-3">
                                what will come has already come
                            </p>

                            <p id="choose-destination" className="mt-6">
                                Choose your destination:
                            </p>

                            <div className="navbar-menu navbar-menu-choose-destination navbar-cancel-animation">
                                <Link to='/about'>
                                    <div id="navbar-about" className="navbar-item">
                                        <p>About</p>
                                    </div>
                                </Link>
                                <Link to='/decolonize-the-art-world'>
                                    <div id="navbar-decolonize" className="navbar-item">
                                        <p>Decolonize the Art World</p>
                                    </div>
                                </Link>
                                <Link to='/imaginarium'>
                                    <div id="navbar-imaginarium" className="navbar-item">
                                        <p>Imaginarium</p>
                                    </div>
                                </Link>
                                <Link to='/the-lab'>
                                    <div id="navbar-the-lab" className="navbar-item">
                                        <p>The Lab</p>
                                    </div>
                                </Link>
                                <Link to='/portal'>
                                    <div id="navbar-portal" className="navbar-item">
                                        <p>Portal</p>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
            }
        </div> 
    )
}

export default SolarSystem
