import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SolarSystem from '../components/solar-system'

import "../styles/index.scss"
import "../styles/solar-system.css"
import "../styles/custom.css"

const IndexPage = () => {

    return (
      <Layout>

        <SEO title="Home" />

        <SolarSystem />

      </Layout>
    )
}

export default IndexPage
